.toast-top-center {
  top: 50px;
}

.toast-title {
  display: none;
}

.toast-message {
  font-size: 12px;
}

.toast-container .ngx-toastr {
  border-radius: 50px;
  padding: 10px 30px;
  text-align: center;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.toast-error {
  background-color: $toast-red-color;
  background-image: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.toast-success {
  background-color: $toast-blue-color;
  background-image: none;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
}

.toast-warning {
  background-image: none;
}
