/* You can add global styles to this file, and also import other style files */
@import "scss/materialicons";
@import "scss/variables";
@import "scss/mixins";
@import "scss/layout";
@import "scss/forms";
@import "scss/commons";
@import "scss/modals";
@import "scss/toastr";

.placeholder {
  background-color: inherit !important;
}
