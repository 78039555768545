form {
  padding: 10px 0px;

  .form-group {
    display: inline-block;

    label {
      font-size: 10px;
      text-transform: uppercase;
      color: $label-color;
      font-weight: 500;
      margin-bottom: 0px;
    }

    .form-control {
      margin-top: 0px;
    }
  }

  .form-control {
    border: 1px solid $border-form;
    box-shadow: none;
    outline: none !important;
    margin: 10px 0px;
    border-radius: 4px;
    font-size: 14px;

    @include placeholder {
      color: $placeholder-color;
      font-weight: 100;
      font-size: 14px;
    }

    &:focus {
      outline: none !important;
      border: 1px solid $border-form;
      box-shadow: none;
    }
  }

  .hint-text {
    font-size: 10px;
    color: #cccccc;
    margin-top: -10px;
  }

  .dropdown-control {
    cursor: pointer;
    .placeholder {
      @include unselectable;
      color: $placeholder-color;

      &.selected {
        color: $text-black;
      }

      .material-icons {
        position: absolute;
        top: 8px;
        right: 10px;
        color: $label-color;
        font-size: 20px;
      }
    }
  }

  .btn {
    height: 41px;
    font-size: 14px;
    padding: 10px 10px;
    width: 100%;
    margin-top: 10px;
    background: linear-gradient(96deg, $accent-color-secondary, $accent-color-lighter);
    border: none;
    border-radius: 4px;

    &:focus {
      outline: none !important;
      border: none;
      box-shadow: none;

      &:active {
        outline: none !important;
        border: none;
        box-shadow: none;
      }
    }
  }
}

.btn {
  font-size: 12px;
  border-radius: 15px;

  .material-icons {
    display: inline-block;
    vertical-align: middle;
    font-size: 14px;
    margin-right: 5px;
  }

  &.lg {
    font-size: 14px;
    border-radius: 5px;
    min-width: 100px;
  }

  span {
    display: inline-block;
    vertical-align: middle;
  }

  &.btn-primary {
    color: #fff;
    background-color: $accent-color;
    border-color: $accent-color;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.btn-secondary {
    color: $text-black;
    background-color: white;
    border-color: $border-form;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.btn-white-noborder {
    color: $text-black;
    background-color: white;
    border: none!important;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.btn-red {
    color: #fff;
    background-color: $red-color;
    border-color: $red-color;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  &.btn-outline {
    color: #fff;
    background-color: transparent;
    border-color: #fff;

    &:focus {
      outline: none;
      box-shadow: none;
    }
  }
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
  color: #fff;
  background-color: $accent-color;
  border-color: $accent-color;
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show>.btn-primary.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show>.btn-secondary.dropdown-toggle {
  color: $text-black;
  background-color: white;
  border-color: $border-form;
}

.btn-secondary:not(:disabled):not(.disabled).active:focus, .btn-secondary:not(:disabled):not(.disabled):active:focus, .show>.btn-secondary.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}

.btn-white-noborder:not(:disabled):not(.disabled).active, .btn-white-noborder:not(:disabled):not(.disabled):active, .show>.btn-white-noborder.dropdown-toggle {
  color: $text-black;
  background-color: white;
  border: none !important;
}

.btn-white-noborder:not(:disabled):not(.disabled).active:focus, .btn-white-noborder:not(:disabled):not(.disabled):active:focus, .show>.btn-white-noborder.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}


.btn-red:not(:disabled):not(.disabled).active, .btn-red:not(:disabled):not(.disabled):active, .show>.btn-red.dropdown-toggle {
  color: #fff;
  background-color: $red-color;
  border-color: $red-color;
}

.btn-red:not(:disabled):not(.disabled).active:focus, .btn-red:not(:disabled):not(.disabled):active:focus, .show>.btn-red.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}

.btn-outline:not(:disabled):not(.disabled).active, .btn-outline:not(:disabled):not(.disabled):active, .show>.btn-outline.dropdown-toggle {
  color: #fff;
  background-color: transparent;
  border-color: #fff;
}

.btn-outline:not(:disabled):not(.disabled).active:focus, .btn-outline:not(:disabled):not(.disabled):active:focus, .show>.btn-outline.dropdown-toggle:focus {
  outline: none;
  box-shadow: none;
}

.modal-dropdown {
  padding: 0px;
  box-shadow: 0 4px 20px 1px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  border: 1px solid $border-color;

  .dropdown-btn {
    font-size: 14px;
    padding: 5px 10px;
    width: 428px;
    cursor: pointer;

    &:hover {
      background-color: $light-bg-border;
    }

    &.sm {
      width: 200px;
    }
  }

  .dropdown-item {
    &.option {
      font-size: 14px;
      cursor: pointer;
      .material-icons {
        display: inline-block;
        vertical-align: sub;
        font-size: 18px;
      }
    }
  }

  .dropdown-item.active, .dropdown-item:active {
    color: $text-black;
    text-decoration: none;
    background-color: $light-bg-border;
  }
}

.dropup .dropdown-toggle::after {
  display: none;
}
