$primary-color : #FFF;
$secondary-color: #03376e;
$accent-color : #0BBF9C;
$accent-color-dark : #0b9472;
$accent-color-darker: #085847;
$accent-color-lighter: #10E4C1;
$accent-color-secondary: #046CD0;
$accent-color-icon: #08816b;

$text-black: #2e2e2e;
$placeholder-color: #929292;
$label-color: #676767;

$light-bg: #e9f0f7;
$light-bg-border: #e5eaef;
$white-bg: #f3f6f9;

$dropdown-bg: #f0f4f9;

$border-color: #f7f7f7;
$border-form: #e3e3e3;
$border-dark: #929292;

$red-color: #da3915;
$red-color-light: #fd552f;

$toast-red-color: #c3214e;
$toast-green-color: #00a959;
$toast-yellow-color: #bdba2b;
$toast-blue-color: #005fcc;

$shell-bg: #F0F4F9;
