@keyframes modal-b-fade {
  from {
    opacity: 0;
  }
  to {
    opacity: 0.5;
  }
}

@keyframes modal-fade {
  from {
    top: -100px;
    opacity: 0;
  }
  to {
    top: 0;
    opacity: 1;
  }
}

.modal-backdrop {
  opacity: 0.5;
  animation: ease-in-out .3s modal-b-fade;
  transition: all .3s ease-in-out;
}

.modal {
  top: -100px;
  animation: ease-in-out .3s modal-fade;
  transition: all .3s ease-in-out;

  &.show {
    top: 0;
  }
}

.modal-content {
  border-radius: 15px;
  border: none;

  .modal-header {
    border-bottom: none;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    text-align: center;
    width: 100%;
    display: block;

    .modal-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 10px;
    }

    .modal-title-image {
      img {
        width: 60px;
        margin-bottom: 10px;
      }
    }

    .modal-subtitle {
      font-size: 12px;
      font-weight: 300;
      max-width: 300px;
      margin: 0 auto;
    }

    .close {
      @include unselectable;
      outline: none;
      position: absolute;
      right: 15px;
      top: 15px;
      font-size: 20px;
      cursor: pointer;
    }
  }

  .modal-body {
    padding: 15px 20px;
  }

  .modal-footer {
    padding: 20px 35px;
    border-top: none;
    border-bottom-right-radius: 15px;
    border-bottom-left-radius: 15px;
  }
}
