html {
  width: 100%;
  height: 100%;
  /*font-family: 'Open Sans', sans-serif;*/
  font-family: 'Lato', sans-serif;
}

body {
  width: 100%;
  height: 100%;
}

.content-header {
  color: white;
  margin-bottom: 25px;
  padding: 0px 40px;

  .title {
    font-size: 28px;
    font-weight: 500;
    letter-spacing: 1px;
  }

  .subtitle {
    font-size: 10px;
    font-weight: 100;
  }
}

.content {
  width: 100%;
  height: calc(100% - 90px);
  overflow-x: hidden;
  overflow-y: scroll;
  padding: 0px 40px;
}

.row {
  .row-title {
    margin: 0px 15px;
    font-size: 14px;
    font-weight: 500;
  }
}

.card {
  border-radius: 10px;
  -webkit-box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
  box-shadow: 0 4px 20px 1px rgba(0,0,0,.06), 0 1px 4px rgba(0,0,0,.08);
  border: none;
  position: relative;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;

  .card-body {
    display: flex;
    -webkit-box-flex: 1;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 1.25rem;
  }
}

.card-table {
  .card-table-header {
    border-bottom: 1px solid $border-color;
    padding: 10px 20px;

    .align-left {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      padding: 5px 0px;
    }

    .align-right {
      display: inline-block;
      float: right;
    }
  }

  .card-table-body {
    width: 100%;
    overflow: scroll;

    table {
      width: 100%;
    }

    th {
      text-align: inherit;
      font-size: 11px;
      font-weight: 500;
      padding: 10px 20px;
      text-transform: uppercase;
      background: $white-bg;
      border-bottom: 1px solid $border-color;
    }

    td {
      font-size: 14px;
      font-weight: 400;
      padding: 10px 20px;
      span {
        display: inline-block;
        vertical-align: middle;
        margin-right: 5px;

        &.badge {
          padding: 2px 10px;
          border: 1px solid $label-color;
          color: $label-color;
          font-size: 11px;
          border-radius: 4px;

          &.green {
            border: 1px solid $toast-green-color;
            color: $toast-green-color;
          }

          &.red {
            border: 1px solid $toast-red-color;
            color: $toast-red-color;
          }

          &.capitalize {
            text-transform: capitalize;
          }
        }

        img {
          width: 18px;
          filter: invert(0.7);
        }
      }
    }
  }
}

.col, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-auto, .col-lg, .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-auto, .col-md, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-auto, .col-sm, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-auto {
  margin-bottom: 10px;
}
