.carousel {
  outline: none !important;
  @include center-vertically;

  .carousel-inner {
    padding-bottom: 60px;
  }
  .carousel-item {
    text-align: center;
    height: 420px;

    img {
      width: 300px;
      height: 300px;
    }

    .caption {
      margin-top: 20px;
      .title {
        font-size: 18px;
        font-weight: 500;
      }

      .subtitle {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 200;
      }
    }
  }
}

#login-slides {
  @include center-vertically;
  width: 100%;

  .swiper-slide {
    text-align: center;
    padding: 50px;

    img {
      width: 300px;
      height: 300px;
    }

    .caption {
      margin-top: 20px;
      .title {
        font-size: 18px;
        font-weight: 500;
      }

      .subtitle {
        margin-top: 10px;
        font-size: 12px;
        font-weight: 200;
      }
    }
  }

  .swiper-pagination-bullets {
    .swiper-pagination-bullet {
      border-radius: 2px;
      width: 25px;
      height: 4px;
    }

    .swiper-pagination-bullet-active {
      opacity: 1;
      background: $accent-color-lighter;
    }
  }
}

.link {
  border-bottom: 1px dotted #929292;
  cursor: pointer;
}

.error-msg {
  font-size: 10px;
  color: #f36b52;
  text-align: center;
  visibility: hidden;
}

.loading-button {
  img {
    width: 20px;
  }
}

.password-field {
  position: relative;
  .show-password {
    @include unselectable;
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 16px;
    color: $placeholder-color;
    cursor: pointer;
  }
}

.tooltip-inner {
  background: white;
  color: $label-color;
  font-size: 11px;
  font-weight: 600;
  box-shadow: 0 4px 20px 10px rgba(0, 0, 0, 0.06), 0 1px 4px rgba(0, 0, 0, 0.08);
  padding: 5px 15px;
}

.bs-tooltip-auto[x-placement^="right"] .arrow::before,
.bs-tooltip-right .arrow::before {
  border-right-color: white;
}

.bs-tooltip-auto[x-placement^="bottom"] .arrow::before,
.bs-tooltip-bottom .arrow::before {
  border-bottom-color: white;
}

.dropdown-toggle::after {
  display: none;
}

.empty-view {
  width: 100%;
  text-align: center;
  padding: 40px 0px;

  .icon {
    img {
      width: 72px;
      opacity: 0.7;
    }
  }

  .message {
    font-size: 14px;
    padding: 10px;
    color: $placeholder-color;
  }
}

.inline-block {
  display: inline-block;

  &.top {
    vertical-align: top;
  }
}

.custom-tabs {
  .tab-item {
    display: inline-block;
    padding: 10px 20px;
    color: white;
    background: #00000008;
    font-size: 12px;
    cursor: pointer;

    &.active {
      background: #00000017;
      border-bottom: 2px solid white;
    }
  }
}

.hover-button {
  display: inline-block;
  vertical-align: middle;
  margin: 0px 5px;
  cursor: pointer;
  opacity: 0.4;

  &:hover {
    opacity: 1;
  }

  .material-icons {
    font-size: 16px;

    &.green {
      color: $toast-green-color;
    }

    &.red {
      color: $red-color-light;
    }

    &.blue {
      color: $toast-blue-color;
    }

    &.yellow {
      color: $toast-yellow-color;
    }
  }
}

.form-group {
  .check-box {
    position: absolute;
    top: 0px !important;
    right: 20px;
  }
}

.swiper-slide {
  .header-title {
    font-size: 14px;
    font-weight: 700;
    text-align: center;
    background: $white-bg;
    border-radius: 4px;
    padding: 8px;
  }
}

.offset-top-8 {
  margin-top: 80px;
}

.material-icons {
  &.red {
    color: $red-color;
  }
}

.card {
  .card-header {
    font-size: 14px;
    background: white;
    border-bottom: 1px solid $border-color;
    border-radius: 10px 10px 0px 0px;
  }

  &.height-400 {
    height: 400px;
  }

  .card-body {
    overflow: scroll;
    margin-bottom: 10px;
  }
}
